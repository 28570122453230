<template>
  <div class="gutter" style="height: calc(100% - 50px)">
    <a-row :gutter="16" style="height: 100%">
      <a-col class="gutter-row" :span="15" style="height: calc(100%)">
        <Left
          :taskId="taskId"
          :isSimple="isSimple"
          :readonly="readonly"
          @transmitTask="whenTransmitTask"
          v-on="$listeners"
          @triggerBorder="whenDone"
        />
      </a-col>
      <a-col class="gutter-row" :span="9" style="height: calc(100%)">
        <Right
          :taskId="taskId"
          :isSimple="isSimple"
          :readonly="readonly"
          :formId="formId"
          ref="taskRightCmp"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Left from "./TaskLeft.vue";
import Right from "./TaskRight.vue";
export default {
  name: "TaskDetailContent",
  props: {
    taskId: { type: String, default: () => "" },
    isSimple: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
  },
  components: {
    Right,
    Left,
  },
  data() {
    return {
      formId: 0,
    };
  },
  methods: {
    whenTransmitTask() {
      this.formId = new Date().valueOf();
    },
    whenDone() {
      this.$refs["taskRightCmp"].getTaskLogs();
    },
  },
};
</script>

<style lang="scss" scoped>
.editBtn {
  color: $blue_color;
  cursor: pointer;
  z-index: 999;
  display: inline;
}
.gutter {
  overflow: hidden;
}
.gutter-row {
  .ant-card-body {
    height: 100vh;
    overflow: auto;
  }
}
.ant-form-inline .ant-form-item {
  width: 400px;
  margin: 10px 0px;
}
</style>
