<!--
    自定义数据转移器：
    一次缓存5倍数据，减少请求数；
    自定义样式，比a-transfer减少操作次数；
    希望能减少开发复杂度；
-->
<template>
  <div class="my-transfer-container" :style="{ height: height }">
    <div class="my-transfer-cell my-transfer-left" :style="leftStyle">
      <a-card
        :title="leftTitle"
        :bodyStyle="{ height: '100%' }"
        :style="{ height: '100%' }"
      >
        <a-input-search
          :placeholder="leftPlaceholder"
          v-model="left.queryParams.filter"
          @keypress.enter="whenSearchLeft"
          @click="whenSearchLeft"
        />
        <a-table
          bordered
          :rowKey="getLeftRowKey"
          :data-source="left.dataSource"
          :columns="left.columns"
          :pagination="left.queryParams"
          :loading="left.loading"
          :showHeader="false"
          :scroll="{ y: 350 }"
          @change="leftTableChange"
        >
          <div slot="operation" slot-scope="text, record">
            <a-button
              type="default"
              icon="plus"
              v-show="!isSelected(record)"
              @click="whenSelectRow(record)"
            ></a-button>
          </div>
        </a-table>
      </a-card>
    </div>
    <div class="my-transfer-cell my-transfer-right" :style="rightStyle">
      <a-card
        :title="rightTitle"
        :bodyStyle="{ height: '100%' }"
        :style="{ height: '100%' }"
      >
        <a-input-search
          :placeholder="rightPlaceholder"
          v-model="right.queryParams.filter"
          @keypress.enter="whenSearchRight"
          @search="whenSearchRight"
        />
        <a-table
          bordered
          :rowKey="getRightRowKey"
          :data-source="right.dataSource"
          :columns="right.columns"
          :pagination="right.queryParams"
          :loading="right.loading"
          :showHeader="false"
          :scroll="{ y: 350 }"
          @change="rightTableChange"
        >
          <div slot="operation" slot-scope="text, record">
            <a-button
              type="default"
              icon="delete"
              @click="whenDeleteRow(record)"
            ></a-button>
          </div>
        </a-table>
      </a-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
// 可触发 change(selectedData)
const DirectionDefine =
  '{"columns":[],"dataSource":[],"loading":false,"queryParams":{"idList":[],"filter":"","current":1,"pageSize":10,"total":0}}';
class CacheType {
  static fromCache = 1;
  static fromRemote = 0;
  static fromRemoteCache = 2;
}
const operateColumn = {
  title: "操作",
  scopedSlots: { customRender: "operation" },
};
export default {
  name: "MyTransfer",
  props: {
    leftStyle: { type: String, default: () => "" },
    leftLoading: { type: Boolean, default: () => false },
    leftTitle: { type: String, default: () => "待选择数据" },
    leftTableShowHeader: { type: Boolean, default: () => false },
    leftTableRowKey: { type: String, default: () => "id" },
    leftTableRowValue: { type: Function, default: (row) => row["id"] },
    leftTableColumns: { type: [], default: () => [] },
    leftPlaceholder: { type: String, default: () => "请输入关键词" },
    leftQueryParams: { type: Object, default: () => {} },
    leftLoadDataAsync: {
      type: Function,
      default: () =>
        new Promise((resolve) => {
          resolve({ items: [], totalCount: 0 });
        }),
    },

    rightStyle: { type: String, default: () => "" },
    rightLoading: { type: Boolean, default: () => false },
    rightTitle: { type: String, default: () => "待选择数据" },
    rightTableShowHeader: { type: Boolean, default: () => false },
    rightTableRowKey: { type: String, default: () => "id" },
    rightTableRowValue: { type: Function, default: (row) => row["id"] },
    rightTableColumns: { type: [], default: () => [] },
    rightPlaceholder: { type: String, default: () => "请输入关键词" },
    rightQueryParams: { type: Object, default: () => {} },
    rightLoadDataAsync: { type: [Function, null], default: null },
    rightOutterFilter: { type: Function, default: (row) => true },

    existsIdList: { type: Array, default: () => [] },
    existsList: { type: Array, default: () => [] },
    height: { type: String, default: () => "height: calc(100% - 40px)" },
    afterSelectRow: { type: Function, default: (row) => row },
  },
  data: () => {
    return {
      left: JSON.parse(DirectionDefine),
      right: JSON.parse(DirectionDefine),
      selectedIdList: [],
      selectedList: [],
      newSelectedCount: 0,
      cache: {
        current: 1,
        data: [],
      },
    };
  },
  methods: {
    getLeftRowKey(row) {
      return row[this.leftTableRowKey];
    },
    getRightRowKey(row) {
      return row[this.rightTableRowKey];
    },
    isSelected(row) {
      const value = this.leftTableRowValue(row);
      return this.selectedIdList.indexOf(value) >= 0;
    },
    whenDeleteRow(row) {
      const value = this.leftTableRowValue(row);
      {
        const idx = this.selectedIdList.indexOf(value);
        if (idx >= 0) {
          this.selectedIdList.splice(idx, 1);
        }
      }
      {
        const idx = this.selectedList.indexOf(row);
        if (idx >= 0) {
          this.selectedList.splice(idx, 1);
        }
      }
      this.$emit("change", [...this.selectedIdList]);
      this.$emit("delete", value);
      this.$emit("deleteRow", row);
      this.loadRightData();
      this.loadLeftData(CacheType.fromCache);
    },
    whenSelectRow(row) {
      const value = this.leftTableRowValue(row);
      this.selectedList.push(this.afterSelectRow(row));
      this.selectedIdList.push(value);

      this.$emit("change", [...this.selectedIdList]);
      this.$emit("add", value);
      this.$emit("addRow", row);
      this.loadRightData();
      this.loadLeftData(CacheType.fromCache);
    },
    whenSearchRight() {
      this.right.queryParams.current = 1;
      this.loadRightData();
    },
    loadRightData() {
      const params = this.right.queryParams;
      params.skipCount = (params.current - 1) * params.pageSize;
      params.maxResultCount = params.pageSize * 5;
      if (this.rightLoadDataAsync) {
        this.rightLoadDataAsync(params).then((res) => {
          this.right.dataSource = res.items;
          this.right.queryParams.total = res.totalCount;
        });
      } else {
        let data = this.selectedList.filter(this.rightOutterFilter);
        const innerFilter = this.getDefaultRightFilter();
        data = data.filter(innerFilter);
        this.right.dataSource = data.slice(
          params.skipCount,
          params.skipCount + params.pageSize
        );
        this.right.queryParams.total = data.length;
      }
    },
    getDefaultRightFilter() {
      const columns = this.rightTableColumns;
      const filter = this.right.queryParams.filter.toUpperCase();
      if (filter)
        return (row) => {
          for (let index = 0; index < columns.length; index++) {
            const element = columns[index];
            if (element.dataIndex) {
              const value = row[element.dataIndex] || "";
              if (value.toString().toUpperCase().indexOf(filter) >= 0)
                return true;
            }
          }
          return false;
        };
      return (row) => true;
    },
    whenSearchLeft() {
      this.left.queryParams.current = 1;
      this.loadLeftData();
    },
    loadLeftData(cacheType = CacheType.fromRemote) {
      if (cacheType) {
        const params = this.left.queryParams;
        const getId = this.leftTableRowValue;
        const data = this.cache.data.filter(
          (x) => this.selectedIdList.indexOf(getId(x)) < 0
        );
        const skip = (params.current - this.cache.current) * params.pageSize;
        if (
          data.length - skip < params.pageSize &&
          cacheType !== CacheType.fromRemoteCache
        ) {
          this.loadLeftData();
        } else {
          params.total = params.originalTotal - this.newSelectedCount;
          this.left.dataSource = data.slice(skip, skip + params.pageSize);
        }
      } else {
        this.left.loading = true;
        const params = this.left.queryParams;
        params.skipCount = (params.current - 1) * params.pageSize;
        params.maxResultCount = params.pageSize * 5;
        this.leftLoadDataAsync(params).then((res) => {
          this.left.loading = false;
          this.newSelectedCount = 0;
          this.cache.data = res.items;
          this.cache.current = params.current;
          this.left.queryParams.originalTotal = res.totalCount;
          this.loadLeftData(CacheType.fromRemoteCache);
        });
      }
    },
    rightTableChange(pagination) {
      this.right.queryParams.current = pagination.current;
      this.loadRightData();
    },
    leftTableChange(pagination) {
      this.left.queryParams.current = pagination.current;
      this.loadLeftData(CacheType.fromCache);
    },
  },
  watch: {
    leftLoading: {
      handler() {
        this.left.loading = this.leftLoading;
      },
      deep: true,
      immediate: true,
    },
    leftTableColumns: {
      handler() {
        if (this.leftTableColumns != null) {
          const columns = this.left.columns;
          columns.splice(0, columns.length);
          columns.push(...this.leftTableColumns);
          columns.push(operateColumn);
        }
      },
      deep: true,
      immediate: true,
    },
    rightTableColumns: {
      handler() {
        if (this.rightTableColumns != null) {
          const columns = this.right.columns;
          columns.splice(0, columns.length);
          columns.push(...this.rightTableColumns);
          columns.push(operateColumn);
        }
      },
      deep: true,
      immediate: true,
    },
    existsIdList: {
      handler() {
        if (this.existsIdList != null) {
          this.selectedIdList.splice(
            0,
            this.selectedIdList.length,
            ...this.existsIdList
          );
        }
      },
      deep: true,
      immediate: true,
    },
    existsList: {
      handler() {
        if (this.existsList != null) {
          this.selectedList.splice(0, this.selectedList.length);
          this.selectedList.push(...this.existsList);
          this.whenSearchRight();
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
    leftQueryParams: {
      handler() {
        if (this.leftQueryParams != null) {
          Object.assign(this.left.queryParams, this.leftQueryParams);
          this.whenSearchLeft();
        }
      },
      deep: true,
      immediate: true,
    },
    rightQueryParams: {
      handler() {
        if (this.rightQueryParams != null) {
          Object.assign(this.right.queryParams, this.rightQueryParams);
          this.whenSearchRight();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.my-transfer-container {
  vertical-align: top;
  position: relative;
}
.my-transfer-cell {
  display: block;
  width: 50%;
  height: 100%;
  padding: 1em;
  margin: 0;
  float: left;
  position: relative;
}
</style>
