<template>
  <a-card title="基础信息" class="task-execute-left-basic">
    <div slot="extra" v-if="!readonly">
      <a-button type="primary" @click="editTask" style="margin-right: 1em">
        保存
      </a-button>
    </div>
    <a-form
      :layout="formLayout"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item label="任务名称">
            <a-input v-model="taskForm.name" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-form
      :layout="formLayout"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="项目计划">
            <label>
              {{ taskForm.projectName + "/" + taskForm.planName }}
            </label>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="责任人">
            <label v-if="taskForm.ownerName">{{ taskForm.ownerName }} </label>
            <label v-else>待认领</label>
            <a-button type="link" v-if="isClaim" @click="whenClaimTask"
              >认领</a-button
            >
            <a-button type="link" v-else-if="isTransfer" @click="whenTransfer">
              转交
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-form
      :layout="formLayout"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="预计开始日期">
            <a-date-picker
              v-model="taskForm.startDate"
              :disabled-date="disabledTaskStartDate"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              type="date"
              placeholder="预计开始日期"
              :disabled="readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="预计完成日期">
            <a-date-picker
              v-model="taskForm.endDate"
              :disabled-date="disabledTaskEndDate"
              @openChange="handleTaskEndOpenChange"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              type="date"
              placeholder="预计完成日期"
              :disabled="readonly"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-form
      :layout="formLayout"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="进度">
            <a-input
              v-model="taskForm.schedule"
              suffix="%"
              :disabled="readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="状态">
            <a-select
              style="width: 120px"
              @change="handleChange"
              v-model="taskForm.status"
            >
              <a-select-option :value="0"> 未启动 </a-select-option>
              <a-select-option :value="5"> 进行中 </a-select-option>
              <a-select-option :value="8"> 待验收 </a-select-option>
              <a-select-option :value="10"> 已完成 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-form
      :layout="formLayout"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-form-item label="描述" class="textarea">
        <!-- <a-input
          v-model="taskForm.description"
          type="textarea"
          :autoSize="{ minRows: 3, maxRows: 5 }"
          :disabled="readonly"
        /> -->
        <div ref="editor"></div>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getTask, updateTask, claimTransfer } from "@/api/taskManage.js";
import { DirectUploadFileToAliyunSpecificPosition } from "@/api/figureDocument.js";
import E from "wangeditor";

export default {
  name: "TaskExecuteLeftBasic",
  props: {
    taskId: { type: String, default: () => "" },
    isSimple: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
  },
  data: () => {
    return {
      formLayout: "inline",
      open: false,
      rules: {},
      isTransfer: false,
      isClaim: false,
      taskForm: {
        id: "",
        name: "",
        startDate: null,
        endDate: null,
        projectName: "",
        planName: "",
        desc: "",
        ownerId: "",
        ownerName: "",
        status: "0",
        statusText: "",
        schedule: 0,
        description: "",
      },
      editor: null,
    };
  },
  created() {
    this.getTaskInfo();
  },
  mounted() {
    this.taskForm.description = "";

    this.editor = new E(this.$refs.editor);
    this.editor.customConfig = this.editor.customConfig
      ? this.editor.customConfig
      : this.editor.config;
    this.editor.customConfig.onchange = (html) => {
      if (html && html.length > 3900) {
        this.$message.warning("字数超过限制！");
        this.editor.cmd.do("undo");
      } else {
        this.taskForm.description = html;
      }
    };

    this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      console.log("resultFiles", resultFiles);
      const formData = new FormData();
      resultFiles.forEach((file) => {
        formData.append("files[]", file);
      });
      DirectUploadFileToAliyunSpecificPosition(formData).then((res) => {
        console.log("DirectUploadFileToAliyunSpecificPosition", res);
        // 上传图片，返回结果，将图片插入到编辑器中
        res.forEach((item) => {
          insertImgFn(item.fileAliyunOssLink);
        });
      });
    };
    // 隐藏“网络图片”tab
    this.editor.customConfig.showLinkImg = false;
    this.editor.config.zIndex = 5;
    this.editor.config.menus = ["image", "undo", "redo"];
    this.editor.config.historyMaxSize = 5;
    this.editor.create();
  },
  methods: {
    disabledTaskEndDate(endValue) {
      const startValue = this.taskForm.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    disabledTaskStartDate(startValue) {
      const endValue = this.taskForm.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    handleTaskEndOpenChange(open) {
      this.open = open;
    },
    handleChange(value) {
      this.taskForm.status = value;
    },
    getTaskInfo() {
      getTask(this.taskId).then((res) => {
        this.taskForm = res;
        var userInfo = this.$store.getters.getUserInfo;
        var isSuper = (userInfo || {}).isCompanyAdmin;
        this.isTransfer =
          isSuper ||
          this.taskForm.projectOwnerId == userInfo.id ||
          this.taskForm.ownerId == userInfo.id;
        console.log("isCompanyAdmin:", isSuper);
        console.log("ProjectOwnerId:", this.taskForm.projectOwnerId);
        console.log("CurrentTaskOwnerId:", this.taskForm.ownerId);
        console.log("CurrentUserId:", userInfo.id);

        this.editor.txt.html(res.description);
        //console.log("isCompanyAdmin:",isSuper,this.taskForm.projectOwnerId,this.taskForm.ownerId,userInfo.id);
        if (!this.taskForm.ownerId) this.isClaim = true;
        this.$emit("afterGetForm", res);
      });
    },
    whenTransfer() {
      this.$emit("transfer");
    },
    whenClaimTask() {
      var param = { taskId: this.taskForm.id };
      var _this = this;
      claimTransfer(param).then((res) => {
        if (res) {
          _this.$message.success("认领成功");

          var userInfo = this.$store.getters.getUserInfo;
          _this.taskForm.ownerName = userInfo.fullName;
          _this.taskForm.ownerId = userInfo.id;
          _this.isClaim = false;
          _this.isTransfer = true;
          _this.comment = null;
          _this.$emit("triggerBorder");
        }
      });
    },
    editTask() {
      var _this = this;
      updateTask(_this.taskForm).then((res) => {
        if (res) {
          _this.$message.success("修改任务成功");
          _this.$emit("triggerBorder");
        }
      });
    },
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style lang="scss">
.task-execute-left-basic {
  .ant-form-item {
    width: 100%;
    margin-bottom: 20px;
  }
}

.textarea .ant-input {
  min-height: 100px !important;
  max-height: 100px !important;
  height: 100px !important;
}
</style>
