<template>
  <a-card title="所有动态" class="task-execute-right">
    <div class="comments">
      <a-comment v-for="p in taskLog" :key="p.id">
        <a slot="author">{{ p.userName }}</a>
        <a-avatar
          slot="avatar"
          src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          alt=""
        />
        <p slot="content">
          {{ p.description }}
        </p>
        <a-tooltip
          slot="datetime"
          :title="moment(p.creationTime).format('YYYY-MM-DD HH:mm:ss')"
        >
          <span>{{
            moment(p.creationTime).format("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </a-tooltip>
      </a-comment>
    </div>
    <a-row>
      <a-col :span="24">
        <a-input
          v-model="comment"
          type="textarea"
          :autoSize="{ minRows: 3, maxRows: 5 }"
        />

        <a-button
          type="primary"
          style="float: right; margin-top: 15px"
          @click="createTaskLog"
          >发布</a-button
        >
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import moment from "moment";
import { getTaskLog, createTaskLog } from "@/api/taskManage.js";
export default {
  name: "TaskExecuteRight",
  props: {
    taskId: { type: String, default: () => "" },
    isSimple: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
    formId: { type: Number, default: () => 0 },
  },
  data: () => {
    return {
      moment,
      comment: "",
      taskLog: [],
    };
  },
  created() {
    this.getTaskLogs();
  },
  methods: {
    getTaskLogs() {
      let params = { taskId: this.taskId };
      getTaskLog(params).then((res) => {
        this.taskLog = res;
      });
    },
    createTaskLog() {
      let param = {
        projectTaskId: this.taskId,
        description: this.comment,
      };
      if (!this.comment) {
        this.$message.error("请输入留言");
        return;
      }
      createTaskLog(param).then((res) => {
        if (res && res.id != "") {
          this.getTaskLogs();
          this.$message.success("留言成功");
          this.comment = null;
        }
      });
    },
  },
  watch: {
    formId: {
      handler() {
        if (this.formId > 0) {
          this.getTaskLogs();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.task-execute-right {
  height: 100%;
  .ant-card-body {
    height: 100%;
    .comments {
      height: calc(100% - 10rem);
      overflow-y: auto;
    }
  }
}
</style>
