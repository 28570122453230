<template>
  <a-modal
    :visible="visible"
    title="转交任务"
    :width="600"
    centered
    destroyOnClose
    @cancel="$emit('cancel')"
  >
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="false"
        @click="whenTransfer"
      >
        确认
      </a-button>
    </template>
    <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
        <a-select
          v-model="taskForm.ownerId"
          style="width: 180px"
          ref="select"
          @change="handleSelChange"
        >
          <a-select-option v-for="m in members" :key="m.userId">{{
            m.userName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getMembers } from "@/api/projectmanage.js";
import { claimTransfer } from "@/api/taskManage.js";
export default {
  name: "TransmitTaskDialog",
  props: {
    form: { type: Object, default: () => {} },
    visible: { type: Boolean, default: () => false },
  },
  data() {
    return {
      taskForm: {},
      transferId: null,
      members: [],
    };
  },
  methods: {
    laodMembersAsync() {
      let params = { projectId: this.taskForm.projectId };

      getMembers(params).then((res) => {
        this.members = res;     
        
        if(this.members.findIndex(p=>p.userId==this.taskForm.ownerId)<0){ 
            this.taskForm.ownerId='';
        }
      });
    },
    handleSelChange(value) {
      this.transferId = value;
    },
    whenTransfer() {
      var param = { TaskId: this.taskForm.id, ReferId: this.transferId };

      claimTransfer(param).then((res) => {
        if (res) {
          this.$message.success("转交成功");
          //this.$router.go(0);
          this.$emit("transferSaveSuccess");
        }
      });
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form && this.form.projectId) {
          this.taskForm = { ...this.form };
          this.laodMembersAsync();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
