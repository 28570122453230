<template>
  <a-card title="关联内容" class="task-left-refrence">
    <div slot="extra">
      <a-button
        type="primary"
        @click="whenAddFigureDoc"
        style="margin-right: 1em"
      >
        新增图文档
      </a-button>
      <a-button type="primary" @click="whenAddRow" style="margin-right: 1em">
        关联
      </a-button>
      <a-popover title="提醒" trigger="focus" v-model="refCtrl.showTips">
        <template slot="content">
          <p>请注意点击保存，以免丢失已做的修改</p>
        </template>
        <a-button
          class="btnSave"
          type="primary"
          @click="whenSave"
          style="margin-right: 1em"
          :disabled="!refCtrl.hasChange"
        >
          保存
        </a-button>
      </a-popover>
    </div>
    <a-table
      bordered
      :rowKey="(record) => record.id"
      :data-source="dataSource"
      :columns="columns"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a-button
            type="default"
            shape="circle"
            icon="zoom-in"
            @click="whenViewRow(record)"
          ></a-button>
          <a-button
            type="default"
            shape="circle"
            icon="delete"
            @click="whenDeleteRow(record)"
          ></a-button>
        </a-space>
      </div>
      <div slot="version" slot-scope="text, record">
        <a-spin :spinning="record.loading">
          <a-select
            v-model="record.newVersion"
            @change="whenChangeVersion(record)"
          >
            <a-select-option
              v-for="(item, index) in record.versions"
              :key="`${record.id}-versions-${index}`"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-spin>
      </div>
    </a-table>
    <EditDialog
      :existsData="refCtrl.existsData"
      :visible="refCtrl.visible"
      :isLoadingData="refCtrl.loading"
      :taskId="taskId"
      @cancel="refCtrl.visible = false"
      @submit="whenSavedForm"
    />

    <a-modal
      v-model="visibleNew"
      title="新增图文档"
      :width="1200"
      :maskClosable="false"
      :cancel-button-props="{ style: { display: 'none' } }"
      :ok-button-props="{ style: { display: 'none' } }"
      ok-text="开始上传"
      @ok="handleUploadOk"
    >
      <add-doc ref="refAddDoc" @uploadOk="whenUploadOk" />
    </a-modal>
  </a-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import AddDoc from "@/views/FigureDocManage/components/AddDoc.vue";
import { create } from "@/api/figureDocument.js";
import { saveRefrence } from "@/api/taskManage";
import uniqBy from "lodash/uniqBy";
import EditDialog from "@/views/TaskManage/Refrences/EditDialog.vue";
import {
  deleteRefrence,
  updateManyRefrence,
  getRefrenceList,
  getBindedDataAsync,
} from "@/api/taskManage";
import * as documentApi from "@/api/figureDocument.js";
import * as materialApi from "@/api/material.js";
export default {
  name: "TaskExecuteLeftRefrence",
  props: {
    taskId: { type: String, default: () => "" },
    isSimple: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
  },
  components: {
    EditDialog,
    AddDoc,
  },
  data: () => {
    return {
      columns: [
        {
          title: "关联类型",
          dataIndex: "attachTypeName",
          ellipsis: true,
        },
        {
          title: "关联名称",
          dataIndex: "attachName",
          ellipsis: true,
        },
        {
          title: "关联编码",
          dataIndex: "attachCode",
          ellipsis: true,
        },
        {
          title: "规格类型",
          dataIndex: "specification",
          ellipsis: true,
        },
        {
          title: "版本",
          scopedSlots: { customRender: "version" },
          ellipsis: true,
        },
        {
          title: "操作",
          scopedSlots: { customRender: "operation" },
        },
      ],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      orginalDataSource: [],
      dataSource: [],
      loading: false,
      refCtrl: {
        visible: false,
        loading: false,
        existsData: [],
        hasChange: false,
        showTips: false,
        changes: [],
      },
      visibleNew: false,
    };
  },
  created() {
    this.loadDataAsync();
  },
  methods: {
    whenChangeVersion(row) {
      if (!row.newVersions) {
        row.loading = true;
        const params = {
          includeItemIdList: [row.sourceItemId],
        };
        switch (row.attachType) {
          case "Document":
            documentApi.getTaskVersions(params).then((items) => {
              row.newVersions = items || [];
              row.loading = false;
              this.whenChangeVersion(row);
            });
            break;
          case "Material":
            materialApi.getTaskVersions(params).then((items) => {
              for (let index = 0; index < items.length; index++) {
                const element = items[index];
                element.code = element.fullCode;
              }
              row.newVersions = items || [];
              row.loading = false;
              this.whenChangeVersion(row);
            });
            break;
        }
      } else {
        const versions = row.newVersions;
        const cur = versions.find((x) => x.version === row.newVersion);
        Object.assign(row, {
          sourceItemId: cur.sourceItemId,
          attachName: cur.name,
          attachCode: cur.code,
          specification: cur.specification,
          version: cur.version,
        });
        this.refCtrl.hasChange = true;
        this.refCtrl.showTips = true;
        setTimeout(() => {
          this.refCtrl.showTips = false;
        }, 2000);
        this.refCtrl.changes.push(row);
      }
    },
    formatVersion(item) {
      return /^\d$/.test(item) ? `V${item}` : item;
    },
    whenSave() {
      const toUpdate = this.refCtrl.changes;
      const postData = toUpdate.map((x) => {
        return {
          id: x.id,
          version: x.version,
        };
      });
      updateManyRefrence(postData)
        .then(() => {
          this.$message.success("保存成功！");
          this.loadDataAsync();
        })
        .catch(() => {
          this.$message.warning("保存失败！");
          this.loadDataAsync();
        });
      this.refCtrl.changes = [];
      this.refCtrl.hasChange = false;
    },
    whenSavedForm() {
      this.refCtrl.visible = false;
      this.loadDataAsync();
      this.getBindedData();
    },
    whenAddRow() {
      if (this.refCtrl.existsData.length === 0 && this.pagination.total > 0) {
        this.getBindedData();
      }
      this.refCtrl.visible = true;
    },
    getBindedData() {
      this.refCtrl.loading = true;
      getBindedDataAsync({
        taskId: this.taskId,
      }).then((res) => {
        this.refCtrl.loading = false;
        this.refCtrl.existsData = this.afterGetData(res);
      });
    },
    whenViewRow(row) {
      if (row.attachType == "Document") {
        let params = {
          id: row.sourceItemId,
          isFrom: "other-historyModal",
          figureDocumentId: row.sourceId,
        };
        this.$router.push({ name: "FigureDocManageDetailHis", params });
      } else {
        this.$router.push("/MaterialManagement/edit/" + row.sourceItemId);
      }
      //this.$message.error("暂不支持预览！");
    },
    whenDeleteRow(row) {
      const self = this;
      this.$confirm({
        title: `确认要删除${row.attachName}?`,
        onOk() {
          deleteRefrence(row.id).then(() => {
            self.loadDataAsync();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      this.loadDataAsync();
    },
    loadDataAsync() {
      if (this.taskId) {
        this.loading = true;
        getRefrenceList({
          taskId: this.taskId,
          skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
          maxResultCount: this.pagination.pageSize,
        }).then((res) => {
          this.loading = false;
          this.dataSource = this.afterGetData(res.items);
          this.orginalDataSource = JSON.parse(JSON.stringify(this.dataSource));
          this.pagination.total = res.totalCount;
        });
      }
    },
    afterGetData(items) {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.attachType = element.attachTypeEnumName;
        element.newVersion = element.version;
        element.loading = false;
      }
      return items;
    },
    whenAddFigureDoc() {
      this.visibleNew = true;
    },

    //开始上传
    whenUploadOk() {
      let aliyunFileDtos = this.$refs.refAddDoc.aliyunOssFileDtos;
      let param = {
        aliyunOssFileDtos: aliyunFileDtos,
      };
      console.log("param:", param);
      create(param)
        .then((res) => {
          this.visibleNew = false;
          this.$refs.refAddDoc.clearClick();

          // 关联后 刷新table列表
          this.saveRefrence(res);
        })
        .catch((errorMsg) => {
          this.$refs.refAddDoc.clearClick();
          console.log("errorMsg:", errorMsg);
        });
    },

    handleUploadOk() {
      let arr = this.$refs.refAddDoc.dataSource;
      console.log("arr", arr);
      let uniqArr = uniqBy(arr, "fileOrigionName");
      if (uniqArr.length != arr.length) {
        this.$message.error("文档名称不能相同，请先删除重复文档！");
        return;
      }

      let params = {
        figureDocuments: [],
      };
      arr.forEach((item) => {
        params.figureDocuments.push({
          fileAliyunFullPath: item.fileAliyunFullPath,
          DocType: item.docType,
        });
      });

      create(params)
        .then((res) => {
          console.log("create", res);
          this.visibleNew = false;
          this.$refs.refAddDoc.clearClick();

          // 关联后 刷新table列表
          this.saveRefrence(res);
        })
        .catch(() => {
          this.$refs.refAddDoc.clearClick();
        });
    },

    saveRefrence(arr) {
      this.loading = true;
      let sources = [];
      arr.forEach((item) => {
        sources.push({
          attachType: "Document",
          sourceId: item.figureDocumentId,
          version: item.docVersion,
        });
      });

      saveRefrence({
        taskId: this.taskId,
        sources,
        isAppend: true,
      })
        .then(() => {
          this.loading = false;
          this.$message.success("保存成功!");
          this.loadDataAsync();
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("保存失败!");
        });
    },
  },
};
</script>

<style></style>
