<template>
  <div class="task-execute-left">
    <Basic
      :taskId="taskId"
      :isSimple="isSimple"
      :readonly="readonly"
      @transfer="whenTransfer"
      @afterGetForm="whenGetForm"
      v-on="$listeners"
      ref="leftBasicComponent"
    />
    <Refrence :taskId="taskId" :isSimple="isSimple" :readonly="readonly" />
    <!-- 转交任务 -->
    <TransmitTaskDialog
      @submited="$emit('transmitTask')"
      :visible="modalTransVisible"
      @cancel="modalTransVisible = false"
      :form="taskForm"
      @transferSaveSuccess="transferSaveSuccess"
    />
  </div>
</template>

<script>
import TransmitTaskDialog from "../TransmitTaskDialog";
import Basic from "./TaskLeftBasic.vue";
import Refrence from "./TaskLeftRefrence.vue";
export default {
  name: "TaskExecuteLeft",
  components: {
    TransmitTaskDialog,
    Basic,
    Refrence,
  },
  props: {
    taskId: { type: String, default: () => "" },
    isSimple: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
  },
  data: () => {
    return { modalTransVisible: false, taskForm: {} };
  },
  methods: {
    whenTransfer() {
      this.modalTransVisible = true;
    },
    whenGetForm(form) {
      this.taskForm = form;
    },
    transferSaveSuccess() {
      this.modalTransVisible = false;
      this.$refs["leftBasicComponent"].getTaskInfo();
      this.$emit("triggerBorder");
    },
  },
};
</script>
<style lang="scss">
.task-execute-left {
  height: 100%;
  overflow-y: auto;
  //background: #fff;
  .ant-card-body {
    height: 100%;
  }
}
</style>
