<template>
  <a-modal
    :visible="visible"
    title="关联事项"
    width="80vw"
    :destroyOnClose="true"
    :confirmLoading="loading"
    okText="确认"
    cancelText="取消"
    @cancel="$emit('cancel')"
    @ok="whenSave"
  >
    <a-form
      style="max-height: 70vh; height: 600px"
      layout="horizontal"
      :labelCol="{ span: 3 }"
      :wrapperCol="{ span: 8 }"
    >
      <a-form-item label="关联类型">
        <a-select v-model="attachType">
          <a-select-option key="Material" value="Material">
            物料
          </a-select-option>
          <a-select-option key="Document" value="Document">
            图文档
          </a-select-option>
        </a-select>
      </a-form-item>
      <MyTransfer
        :leftTitle="leftTitle"
        :leftLoadDataAsync="loadLeftDataAsync"
        :leftPlaceholder="placeholder"
        :leftTableRowValue="(row) => row['sourceId'] || row['id']"
        :leftTableColumns="columns"
        :leftLoading="isLoadingData || isLoadingLeftData"
        :leftQueryParams="queryParams"
        :rightTitle="rightTitle"
        :rightPlaceholder="placeholder"
        :rightOutterFilter="filterByAttachmentType"
        :rightTableRowValue="(row) => row['sourceId'] || row['id']"
        :rightTableColumns="columns"
        :rightQueryParams="queryParams"
        :existsList="getExistsData"
        :existsIdList="getExistsIdList"
        style="height: 550px"
        :afterSelectRow="afterSelectRow"
        @addRow="whenAddRow"
        @deleteRow="whenDeleteRow"
      />
    </a-form>
  </a-modal>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { saveRefrence } from "@/api/taskManage";
import MyTransfer from "@/components/MyTransfer";
import * as documentApi from "@/api/figureDocument.js";
import * as materialApi from "@/api/material.js";
const columns = [
  {
    title: "编号",
    dataIndex: "code",
    ellipsis: true,
  },
  {
    title: "名称",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "规格型号",
    dataIndex: "specification",
    ellipsis: true,
  },
];
export default {
  name: "ProjectTaskRefrenceEditDialog",
  components: { MyTransfer },
  props: {
    visible: { type: Boolean, default: () => false },
    existsData: { type: Array, default: () => [] },
    taskId: { type: String, default: () => {} },
    isLoadingData: { type: Boolean, default: () => false },
  },
  data: () => {
    return {
      dicts: {},
      attachType: "Material",
      loading: false,
      selectedData: [],
      isLoadingLeftData: false,
      columns,
    };
  },
  methods: {
    whenSave() {
      this.loading = true;
      saveRefrence({
        taskId: this.taskId,
        sources: this.selectedData,
        isAppend:false
      })
        .then(() => {
          this.loading = false;
          this.$message.success("保存成功!");
          this.$emit("submit");
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("保存失败!");
        });
    },
    loadLeftDataAsync(params) {
      params.excludeItemIdList = params.excludeIdList;
      return new Promise((resolve) => {
        if (this.isMaterial) {
          return materialApi.getTaskList(params).then((res) => {
            for (let index = 0; index < res.items.length; index++) {
              const element = res.items[index];
              element.code = element.fullCode;
            }
            resolve(res);
          });
        } else {
          return documentApi.getTaskList(params).then((res) => {
            resolve(res);
          });
        }
      });
    },
    afterSelectRow(row) {
      return {
        ...row,
        attachType: this.attachType,
      };
    },
    whenChangeSelection(selection) {
      this.selectedData.splice(0, this.selectedData.length, ...selection);
    },
    filterByAttachmentType(row) {
      return row.attachType == this.attachType;
    },
    whenAddRow(row) {
      this.selectedData.push({
        sourceId: row.id,
        attachType: this.attachType,
        version: row.version,
      });
    },
    whenDeleteRow(row) {
      const item = this.selectedData.find((x) => x.sourceId == row.sourceId);
      const idx = this.selectedData.indexOf(item);
      if (idx >= 0) {
        this.selectedData.splice(idx, 1);
      }
    },
  },
  computed: {
    leftTitle() {
      return this.isMaterial ? "可选择物料" : "可选择图文档";
    },
    rightTitle() {
      return this.isMaterial ? "已选择物料" : "已选择图文档";
    },
    queryParams() {
      return {
        attachType: this.attachType,
      };
    },
    placeholder() {
      return this.isMaterial
        ? "物料编码、物料名称、规格型号"
        : "文档编码、文档名称";
    },
    getExistsIdList() {
      return this.existsData.map((x) => x.sourceItemId);
    },
    isMaterial() {
      return this.attachType === "Material";
    },
    getExistsData() {
      return this.existsData.map((x) => {
        const item = { ...x };
        item.code = x.attachCode;
        item.name = x.attachName;
        return item;
      });
    },
  },
  watch: {
    existsData: {
      handler() {
        if (this.existsData != null) {
          this.selectedData = this.existsData.map((x) => {
            return {
              sourceId: x.sourceId,
              attachType: x.attachType,
              version: x.version,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
